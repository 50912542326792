<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        viewbox-width="24"
        viewbox-height="24"
        @click="$emit('click')"
    >
        <path
            class="cls-1"
            d="M18.5,16.147a6.75,6.75,0,0,1-13.5,0c0-4.87,4.448-12.212,6.122-14.8a.745.745,0,0,1,1.256,0C14.052,3.935,18.5,11.277,18.5,16.147Z"
        /><path class="cls-1" d="M11.75,19.9A3.75,3.75,0,0,1,8,16.147" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'DropIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
