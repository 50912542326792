<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        viewbox-width="24"
        viewbox-height="24"
        @click="$emit('click')"
    >
        <path
            class="cls-1"
            d="M12.7,15.418V3.75a3,3,0,0,0-6,0V15.418a4.5,4.5,0,1,0,6,0Z"
        />

        <line class="cls-1" x1="9.703" y1="10.5" x2="9.703" y2="17.25" />

        <circle class="cls-1" cx="9.703" cy="18.75" r="1.5" />

        <line class="cls-1" x1="16.453" y1="3.75" x2="21.703" y2="3.75" />

        <line class="cls-1" x1="16.453" y1="8.25" x2="18.703" y2="8.25" />

        <line class="cls-1" x1="16.453" y1="12.75" x2="21.703" y2="12.75" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ThermometerIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
